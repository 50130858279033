import React from "react";
import Composition from "./components/Composition";
function RNG(seed) {
  // LCG using GCC's constants
  this.m = 0x80000000; // 2**31;
  this.a = 1103515245;
  this.c = 12345;

  this.state = seed ? seed : Math.floor(Math.random() * (this.m - 1));
}
RNG.prototype.nextInt = function() {
  this.state = (this.a * this.state + this.c) % this.m;
  return this.state;
}
RNG.prototype.nextFloat = function() {
  // returns in range [0,1]
  return this.nextInt() / (this.m - 1);
}
RNG.prototype.nextRange = function(start, end) {
  // returns in range [start, end): including start, excluding end
  // can't modulu nextInt because of weak randomness in lower bits
  var rangeSize = end - start;
  var randomUnder1 = this.nextInt() / this.m;
  return start + Math.floor(randomUnder1 * rangeSize);
}
RNG.prototype.choice = function(array) {
  return array[this.nextRange(0, array.length)];
}


export default function Collage (props) {
  var rng = new RNG(props.seed);
  // create a new independent random number generator (uses seedrandom under the hood)
  let compositions = [];
  if (props.imageDirectory.images.length <= 0) return <></>
  for (let i = 0; i < 30; i++) {
    console.log(props.imageDirectory.images.length);
    let fileName = "http://hallucinations.aaron.work/output-images/" + props.imageDirectory.images[rng.nextRange(0, props.imageDirectory.images.length)].outputs["background_removal_indexnet"];
    compositions.push(<>
        <img style={{top:rng.nextRange(0,80) + "%", left:rng.nextRange(0,70) + "%"}} key={"layer" + i} src={fileName} className={"absolute top-20 left-0 w-4/12 "} />
    </>)
  }
  return (<><div className="overflow-hidden w-screen h-screen">
    <img src={"http://hallucinations.aaron.work/output-images/" + props.imageDirectory.images[rng.nextRange(0, props.imageDirectory.images.length)].outputs["background_inpainting_deepfillv2_512"]} className={"absolute top-0 left-0 w-full h-full "} />
    {/* <img src={"http://hallucinations.aaron.work/input-images/" + props.imageDirectory.images[rng.nextRange(0, props.imageDirectory.images.length)].input} className={"absolute top-0 left-0 w-full h-full "} /> */}
    {compositions}</div>
  </>)
}
