import logo from "./logo.svg";
import "./App.css";
import Composition from "./components/Composition";
import {useState} from "react";
import {useEffect} from "react";
import {FixedSizeGrid as Grid} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {useMediaQuery} from "react-responsive";
import {Route, Routes} from "react-router-dom";
import Collage from "./Collage";
import AllImagesView from "./AllImagesView";
let columns = 3;
let seed = Math.floor(Math.random() * 8172837);
function App() {
  let [imageDirectory, setImageDirectory] = useState({images: []});
  const isMedium = useMediaQuery({query: "(min-width: 684px)"});
  const isDesktopOrLaptop = useMediaQuery({query: "(min-width: 1224px)"});
  const isBigScreen = useMediaQuery({query: "(min-width: 1824px)"});
  columns = 1;
  if (isMedium) columns = 3;
  if (isDesktopOrLaptop) columns = 5;
  if (isBigScreen) columns = 8;

  const Cell = ({columnIndex, rowIndex, style}) => {
    let image = imageDirectory.images[rowIndex * columns + columnIndex];
    if (image == undefined) return <></>;
    let layers = [
      {
        fileName:
          "http://hallucinations.aaron.work/output-images/" +
          image.outputs["background_inpainting_deepfillv2_512"],
      },
      {
        customClass: "animate-wiggle",
        fileName:
          "http://hallucinations.aaron.work/output-images/" +
          image.outputs["background_removal_indexnet"],
      },
    ];
    return (
      <div style={style}>
        <Composition layers={layers} label={image.title} />
      </div>
    );
  };
  useEffect(() => {
    (async () => {
      let imagesRequest = await fetch(
        "http://hallucinations.aaron.work/image_directory.json"
      );
      let imagesString = await imagesRequest.text();
      setImageDirectory(JSON.parse(imagesString));
    })();
  }, []);
  return (
    <Routes>
      <Route path="/all-images" element={<AllImagesView imageDirectory={imageDirectory} />} />
      <Route path="/" element={<Collage imageDirectory={imageDirectory} seed={seed} />}/>
      {/* <Route index element={<Home />} />
      <Route path="teams" element={<Teams />}>
        <Route path=":teamId" element={<Team />} />
        <Route path="new" element={<NewTeamForm />} />
        <Route index element={<LeagueStandings />} />
      </Route> */}
    </Routes>
  );
}

export default App;
