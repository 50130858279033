import Composition from "./components/Composition";
import {useState} from "react";
import {FixedSizeGrid as Grid} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {useMediaQuery} from "react-responsive";
let columns = 3;
export default function AllImagesView(props) {
  const isMedium = useMediaQuery({query: '(min-width: 684px)'})
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  columns = 1;
  if (isMedium) columns = 3;
  if (isDesktopOrLaptop) columns = 5;
  if (isBigScreen) columns = 8;

  const Cell = ({columnIndex, rowIndex, style}) => {
    let image = props.imageDirectory.images[rowIndex * columns + columnIndex];
    if (image == undefined) return <></>;
    console.log(image)
    let layers = [
      {
        fileName: "http://hallucinations.aaron.work/output-images/" + image.outputs["background_inpainting_deepfillv2_512"],
      },
      {
        customClass: "animate-wiggle",
        fileName:
          "http://hallucinations.aaron.work/output-images/" + image.outputs["background_removal_indexnet"],
      },
    ];
    return (
      <div style={style}>
        <Composition layers={layers} label={image.title} />
      </div>
    );
  };
  return (
        <div className="App w-screen h-screen">
          <AutoSizer>
            {({height, width}) => (
              <Grid
                columnCount={columns}
                columnWidth={width/columns}
                height={height}
                rowCount={Math.ceil(props.imageDirectory.images.length / columns)}
                rowHeight={300}
                width={width}
              >
                {Cell}
              </Grid>
            )}
          </AutoSizer>
        </div>
  );
}