import React from "react";

export default function Composition (props) {
  return <>
    <div className="relative w-60 h-80 inline-block">
      {props.layers.map((layer, i) => <>
        <img key={"layer" + i} src={layer.fileName} className={"absolute top-20 left-0 w-full " + layer.customClass} />
        <div key={"label" + i} className="absolute bottom-60 margin-auto text-xs">{props.label}</div>
      </>
      )}
    </div>
  </>
}
